export const Footer = () => {
  return (
    <footer className="text-neutral-100">
      <div className="container py-5">
        <p>
          &copy; 2023 IMMORTECH PLT [202104000280(LLP0026897-LGN)]. All Right
          Reserved
        </p>
      </div>
    </footer>
  );
};
