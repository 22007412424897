import { useEffect, RefObject } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

interface Options {
  start?: string;
  fadeDuration?: number;
  zoomDuration?: number;
}

function useFadeInOnScroll(ref: RefObject<HTMLElement>, options: Options = {}) {
  const { start = "top 90%", fadeDuration = 2, zoomDuration = 1 } = options;

  useEffect(() => {
    gsap.fromTo(
      ref.current,
      { opacity: 0 },
      {
        opacity: 1,

        duration: fadeDuration,
        scrollTrigger: {
          trigger: ref.current,
          start,
        },
      }
    );
    gsap.fromTo(
      ref.current,
      { scale: 0.5 },
      {
        scale: 1,
        duration: zoomDuration,
        scrollTrigger: {
          trigger: ref.current,
          start,
        },
      }
    );
  }, [ref, start, fadeDuration, zoomDuration]);
}

export default useFadeInOnScroll;
