import { useRef } from "react";
import { BsLaptop, BsTools } from "react-icons/bs";
import useFadeInOnScroll from "./useFadeInOnScroll";

const Services = () => {
  const fadeInRef = useRef(null);

  useFadeInOnScroll(fadeInRef);
  return (
    <section id="services" className=" services">
      <div className="container py-10" ref={fadeInRef}>
        <div className="heading text-center">
          <h2 className="fs-secondary-heading fw-bold">Services</h2>
        </div>
        <div className="even-columns" style={{ marginTop: "4em" }}>
          <div className="box-outer-border flow">
            <div className="box-inner-spacing">
              <div className="text-center services-icon">
                <BsLaptop className="laptop-icon" />
              </div>

              <h2 className="fs-sub-heading fw-bold text-center">
                Software Development
              </h2>
              <ol>
                <li>Web Application</li>
                <li>Mobile Application</li>
                <li>Landing Page</li>
                <li>Software Consultant</li>
              </ol>
            </div>
          </div>
          <div className="box-outer-border flow">
            <div className="box-inner-spacing">
              <div className="text-center services-icon">
                <BsTools className="tools-icon" />
              </div>
              <h2 className="fs-sub-heading fw-bold text-center">
                Repair & Maintenance
              </h2>
              <ol>
                <li>Format & Backup</li>
                <li>Hardware Replacement</li>
                <li>Consulting</li>
                <li>Data Recovery</li>
                <li>Computer Maintenance</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
