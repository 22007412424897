import { useRef } from "react";
import { Autoplay, Lazy, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import useFadeInOnScroll from "./useFadeInOnScroll";

interface ClientDetailsProp {
  name: string;
  image: string;
  link: string;
}

const ClientDetails: ClientDetailsProp[] = [
  {
    name: "TalentLounge",
    image: "images/talentLounge.png",
    link: "https://talentlounge.co/VCF/index.htm",
  },
  {
    name: "MankeyFilm",
    image: "images/mankey.png",
    link: "https://mankeyfilm.com",
  },
  {
    name: "WyOngArchitect",
    image: "images/wyong.png",
    link: "https://wyongarchitect.com",
  },
];

const OurClient = () => {
  const fadeInRef = useRef(null);

  useFadeInOnScroll(fadeInRef);
  return (
    <section id="ourClient" className="ourClient" ref={fadeInRef}>
      <div className="container py-10">
        <div className="heading text-center">
          <h2 className="fs-secondary-heading fw-bold">Clients & Projects</h2>
        </div>
        <div className="py-[50px] ">
          <Swiper
            modules={[Pagination, Scrollbar, Lazy, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            allowTouchMove
            grabCursor={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: 2,
            }}
          >
            {ClientDetails.map((client, index) => {
              return (
                <SwiperSlide key={client.name}>
                  <a href={client.link} target={"opener"}>
                    <img
                      className="m-auto max-w-[200px] min-h-[50px] aspect-ration-5/1"
                      src={client.image}
                      alt={client.name}
                    />
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default OurClient;
