import { useRef } from "react";
import useFadeInOnScroll from "./useFadeInOnScroll";

const AboutUs = () => {
  const fadeInRef = useRef(null);

  useFadeInOnScroll(fadeInRef);
  return (
    <section id="about-us" className=" about-us" ref={fadeInRef}>
      <div className="container py-10">
        <div className="heading text-center">
          <h2 className="fs-secondary-heading fw-bold">About Us</h2>
        </div>
        <div>
          <p className="text-center" style={{ marginTop: "2em" }}>
            At Immortech, we believe there are better way to provide Software
            and Computer Services for people. We are a new tech startup which
            strive to help people to solve problems related to information
            technology. We are excited & ready to help you at any time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
