import { useRef } from "react";
import RenderParticle from "./renderParticle";
import useFadeInOnScroll from "./useFadeInOnScroll";

const HeroSection = () => {
  const fadeInRef = useRef(null);
  useFadeInOnScroll(fadeInRef);
  return (
    <section id="headline" className="padding-block-900 headline">
      <div className="container" ref={fadeInRef}>
        <div className="even-columns">
          <div className="flow headline-column-1">
            <div className="headline-text">
              <span className="fs-primary-heading fw-bold">
                The <span className="blue">Solution</span> You Seek <br />
                <span className="blue small-primary-heading">IT Provider</span>
              </span>
            </div>
            <p>
              We are IT Company which is specialized in designing and
              customizing Web & Mobile Application. Understanding and
              fullfilling your needs is our priority at{" "}
              <span className="blue fw-bold">IMMORTECH PLT</span>.
            </p>
          </div>
          <div className="bg-depth">
            <RenderParticle
              particleId="tsparticles"
              width="100%"
              height="400px"
              densityArea={800}
              densityValue={130}
              distance={150}
              interactivity={true}
              speed={0.65}
            />
          </div>
        </div>
      </div>
      <div className="bg-depth2">
        <RenderParticle
          particleId="tsparticles2"
          width="550px"
          height="100px"
          densityArea={800}
          densityValue={100}
          distance={150}
          interactivity={false}
          speed={0.4}
        />
      </div>
    </section>
  );
};

export default HeroSection;
