import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-scroll";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import React, { forwardRef } from "react";

export const Header = forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
  const gaEventTracker = useAnalyticsEventTracker("Appointment");
  const [navLinkOpen, navLinkToggle] = useState(false);

  const handleNavLinksToggle = () => {
    navLinkToggle(!navLinkOpen);
  };

  const renderClasses = () => {
    let classes = "navlinks";

    if (navLinkOpen) {
      classes += " active";
    }

    return classes;
  };
  return (
    <header className="nav-wrapper" ref={ref}>
      <nav id="navigation">
        <div className="logo">
          <a href="#navigation">
            <img
              className="header-logo"
              src="images/immortech-logo.png"
              alt="Immortech"
            />
          </a>
        </div>
        <ul className={renderClasses()}>
          <Link
            onClick={handleNavLinksToggle}
            className="link"
            activeClass="active"
            to="headline"
            spy={true}
            smooth={true}
            offset={-200}
            duration={1000}
          >
            Home
          </Link>
          <li className="link">
            <a
              href="https://wa.link/10u3s7"
              onClick={() => gaEventTracker("Appointment")}
              target="_blank"
            >
              Appointment
            </a>
          </li>
          <Link
            onClick={handleNavLinksToggle}
            className="link"
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
          >
            Services
          </Link>
          <Link
            onClick={handleNavLinksToggle}
            className="link"
            activeClass="active"
            to="ourClient"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
          >
            Projects
          </Link>
          <Link
            onClick={handleNavLinksToggle}
            className="link"
            activeClass="active"
            to="ourClient"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
          >
            About Us
          </Link>
          <Link
            onClick={handleNavLinksToggle}
            className="link"
            activeClass="active"
            to="contact-us"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
          >
            Contact Us
          </Link>
        </ul>

        <GiHamburgerMenu
          className="hamburger-icon"
          onClick={handleNavLinksToggle}
        />
      </nav>
    </header>
  );
});
