import { useRef } from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdLocationOn, MdEmail } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import useFadeInOnScroll from "./useFadeInOnScroll";
import useWindowDimensions from "./useWindowDimensions";

interface ContactUsDataProps {
  googlemap: string;
  title: string;
  details: ContactUsDetailsProps[];
}
interface ContactUsDetailsProps {
  icon: JSX.Element;
  isLink: boolean;
  link?: string;
  name?: string;
  openMail?: boolean;
}

const ContactUsData: ContactUsDataProps = {
  googlemap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.207759074077!2d101.79288341532747!3d3.0388910546666295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc34a909c367ef%3A0xe7f829848cf850bc!2s11%2C%20Jalan%20SL%202%2F3%2C%20Bandar%20Sungai%20Long%2C%2043000%20Kajang%2C%20Selangor!5e0!3m2!1sen!2smy!4v1659947905726!5m2!1sen!2smy",
  title: "ContactUs",
  details: [
    {
      icon: <MdLocationOn />,
      isLink: false,
      name: "11, Jalan SL 2/3, Bandar Sungai Long,<br/> 43000, Kajang, Selangor",
    },
    {
      icon: <FaFacebookSquare />,
      isLink: true,
      link: "https://fb.me/immortech.co",
    },
    {
      icon: <RiInstagramFill />,
      isLink: true,
      link: "https://instagram.com/immortech.co",
    },
    {
      icon: <IoLogoWhatsapp />,
      isLink: true,
      link: "https://wa.link/10u3s7",
      name: "011-21486313",
    },

    {
      icon: <MdEmail />,
      isLink: true,
      link: "mailto:andy@immortech.co?subject=Subject&body=Body%20goes%20here",
      openMail: true,
      name: "andy@immortech.co",
    },
  ],
};

const ContactUs = () => {
  const fadeInRef = useRef(null);
  const { width } = useWindowDimensions();
  const mapSize = Math.min(450, width * 0.8);

  useFadeInOnScroll(fadeInRef);
  return (
    <section id="contact-us" className=" contact-us" ref={fadeInRef}>
      <div className="container py-10">
        <div className="even-columns">
          <div className="contact-us-column">
            <iframe
              title="location"
              src={ContactUsData.googlemap}
              width={mapSize}
              height={mapSize}
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="googlemap"
            ></iframe>
          </div>

          <div className="flow contact-us-column py-10 px-5 md:px-[4em]">
            <span className="fs-secondary-heading fw-bold text-center">
              {ContactUsData.title}
            </span>
            <div className="py-3">
              {ContactUsData.details.map((d, index) => {
                return (
                  <div className="flex flex-row items-center" key={index}>
                    <div className="pr-[0.5em] text-3xl">{d.icon}</div>
                    {!d.isLink ? (
                      <div
                        className="info-details"
                        dangerouslySetInnerHTML={{
                          __html: d.name ?? "",
                        }}
                      />
                    ) : d.openMail ? (
                      <div
                        className="info-details"
                        onClick={() => window.open(d.link)}
                      >
                        <p>{d.name}</p>
                      </div>
                    ) : (
                      <div className="info-details">
                        <a href={d.link} target={"_blank"}>
                          {d.name ? d.name : d.link}
                        </a>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
