import { useEffect, useState } from "react";
import ReactGA from "react-ga"; // OUR_TRACKING_ID
import "./App.css";
import AboutUs from "./components/aboutUs";
import ContactUs from "./components/contactUs";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import HeroSection from "./components/heroSection";
import Loading from "./components/loading";
import OurClient from "./components/ourClient";
import RenderParticle from "./components/renderParticle";
import Services from "./components/services";

function App() {
  const TRACKING_ID = "G-9TKVLSNBVT";
  ReactGA.initialize(TRACKING_ID);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Use window.onload to detect when the page is fully loaded
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="main-wrapper">
          <Header />
          <HeroSection />
          <div className="bg-depth1">
            <RenderParticle
              particleId="tsparticles1"
              width="250px"
              height="100px"
              densityArea={800}
              densityValue={100}
              distance={150}
              interactivity={false}
              speed={0.4}
            />
          </div>
          <Services />
          <div className="bg-depth3">
            <RenderParticle
              particleId="tsparticles3"
              width="150px"
              height="100px"
              densityArea={800}
              densityValue={100}
              distance={150}
              interactivity={false}
              speed={0.4}
            />
          </div>

          <OurClient />

          <div className="bg-depth4">
            <RenderParticle
              particleId="tsparticles4"
              width="150px"
              height="100px"
              densityArea={800}
              densityValue={100}
              distance={150}
              interactivity={false}
              speed={0.4}
            />
          </div>
          <AboutUs />

          <ContactUs />

          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
