import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import { Container, Engine } from "tsparticles-engine";

interface RenderParticleProps {
  particleId: string;
  width: string;
  height: string;
  distance: number;
  speed: number;
  densityArea: number;
  densityValue: number;
  interactivity: boolean;
}

const RenderParticle = ({
  width,
  height,
  speed,
  densityArea,
  densityValue,
  distance,
  interactivity,
  particleId,
}: RenderParticleProps) => {
  const particlesInit = useCallback(async (engine: Engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await container;
    },
    []
  );
  return (
    <Particles
      id={particleId}
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        style: {
          width,
          height,
        },
        fullScreen: { enable: false },
        fpsLimit: 120,
        interactivity: {
          events: {
            onHover: {
              enable: interactivity,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            repulse: {
              distance: 50,
              duration: 5,
            },
          },
        },
        particles: {
          color: {
            value: "#000000",
          },
          links: {
            color: "#000000",
            distance,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: densityArea,
            },
            value: densityValue,
          },
          opacity: {
            value: 1,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default RenderParticle;
